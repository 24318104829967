(function ($) {
  Drupal.behaviors.basicCarouselFormatter = {
    attach: function (context) {
      var $formatter = $('.basic-carousel-formatter', context);

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change
      // the settings before constructing slick
      $formatter.each(function () {
        var thisFormatter = $(this);
        var carousel = thisFormatter.find('.js-basic-carousel');
        var basicSlide = '.js-basic-carousel__slide';
        var useResponsive = false;
        var respDots = false;
        var respArrows = true;
        var $contentBlockBanner = $('.content-block--v1', thisFormatter);
        var dotText = [];
        var mobileDots;

        $contentBlockBanner.each(function (key) {
          dotText[key] = $(this).attr('data-dot-text');
        });

        // autoplay settings
        var autoplay = carousel.attr('data-slides-autoplay');

        if (typeof autoplay == 'undefined') {
          autoplay = false;
        } else {
          autoplay = true;
        }

        // slides to show
        var slidesToShow = parseInt(carousel.attr('data-slides-show'));

        // var largeBreakpoint = {};
        if (isNaN(slidesToShow)) {
          slidesToShow = 1;
        } else if (slidesToShow > 3) {
          useResponsive = true;
        }

        // slides to scroll
        var slidesToScroll = parseInt(carousel.attr('data-slides-scroll'));

        if (isNaN(slidesToScroll)) {
          slidesToScroll = 1;
        }

        // responsive - show dots for smaller screens
        mobileDots = carousel.attr('data-nav-mobile') === 'dots' ? true : false;
        if (mobileDots) {
          useResponsive = true;
          respDots = true;
          respArrows = false;
        }
        // plain with defaults
        var settingsPlain = {
          slide: basicSlide,
          infinite: true,
          autoplay: autoplay,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          customPaging: function (slider, i) {
            var textIndicator = dotText[i];

            return textIndicator;
          },
          prevArrow: '<span class="icon icon--angle-left slick-prev"></span>',
          nextArrow: '<span class="icon icon--angle-right slick-next"></span>',
          touch: true
        };

        settingsPlain.dots = carousel.data('nav') === 'dots';
        settingsPlain.arrows = carousel.data('nav') === 'arrows';

        // case for custom slides count and dots for mobile
        var settingsResponsive = $.extend({}, settingsPlain, {
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: respArrows,
                dots: respDots,
                slidesToShow: 1,
                slidesToScroll: 1,
                customPaging: function (slider, i) {
                  return (
                    '<button type="button" data-role="none" role="button" aria-required="false" tabindex="0">' +
                    (i + 1) +
                    '</button>'
                  );
                }
              }
            }
          ]
        });

        settingsResponsive.responsive[0].settings.dots = carousel.data('nav-mobile') === 'dots';
        settingsResponsive.responsive[0].settings.arrows = carousel.data('nav-mobile') === 'arrows';

        // Init this carousel with our settings
        if (!useResponsive) {
          carousel.slick(settingsPlain);
        } else {
          carousel.slick(settingsResponsive);
        }
      });
    }
  };
})(jQuery);
